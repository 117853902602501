const Divider = () => {
    return(
        <div style={{
            width : '315px',
            height : '1px',
            background: '#A1A1A1',
            marginTop : '10px'
            }}/>
    )
}

export default Divider;