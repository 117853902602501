import MainMap from "../components/Map/MainMap"
import Header from "../components/Header/TitledHeader"

function MapPage() {
    return (
        <>
            <Header title="학교근처식당"/>
            <MainMap/>
        </>
    )
}

export default MapPage;